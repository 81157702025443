import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table';

import dayjs from 'dayjs'

class Email extends React.Component {
    constructor(props) {
        super(props);
        let { id } = this.props.match.params;
        this.state = {
            id: id,
            loading: true,
            error: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
        this.showConfirmDelete = this.showConfirmDelete.bind(this);
        this.hideConfirmDelete = this.hideConfirmDelete.bind(this);
    }

    componentDidUpdate(prevProps) {
        let { id } = this.props.match.params;
        let oldId = prevProps.match.params.id;
        if (id !== oldId) {
            this.setState({id}, () => this.fetchData());
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    deleteEmail() {
        fetch(this.state.restUri, {method: "delete"})
        .then(
            result => {
                this.setState({
                    deleted: true,
                    showConfirmDelete: false
                });
            },
            error => {
                this.setState({
                    error: true,
                    showConfirmDelete: false
                });
            }                    
        );        
    }

    showConfirmDelete() {
        this.setState({showConfirmDelete: true});
    }

    hideConfirmDelete() {
        this.setState({showConfirmDelete: false});
    }

    fetchData() {
        const host = process.env.REACT_APP_SMTPBUCKET_API;
        const url = new URL(`${host}/emails/${this.state.id}`);
        this.setState({
            loading: true,
            error: false,
            email: null,
            restUri: url
        });
        fetch(url)
            .then(rest => rest.json())
            .then(
                result => {
                    if (result.error) {
                        this.setState({
                            loading: false,
                            error: true
                        });
                        return;
                    }
                    let backLink;
                    if (this.props.location.state && this.props.location.state.fromSearch) {
                        const sender = this.props.location.state.sender;
                        const recipient = this.props.location.state.recipient;            
                        let query = '';
                        if (sender) {
                          query += `sender=${sender}`;
                          if (recipient) {
                            query += '&';
                          }
                        }
                        if (recipient) {
                          query += `recipient=${recipient}`;
                        }
                        backLink = <span><Link to={`/emails?${query}`}>Search Results</Link> &gt; {result.subject ? result.subject : 'No Subject'}</span>
                    }       
                    this.setState({
                        loading: false,
                        error: false,
                        email: result,
                        show: result.html ? 'html' : 'raw',
                        backLink
                    });
                },
                error => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            );
    }

    render() {        
        return (
            <>
                <Container className="pt-3">
                    {this.state.loading && <div className="text-center"><Spinner animation="border" variant="primary" /></div> }
                    {this.state.error && (
                        <>
                            <Alert variant="danger">
                                <Alert.Heading>404 - Not found</Alert.Heading>
                                <p>
                                    The requested e-mail was not found. It may have already been deleted. E-mails are automatically deleted after 30 days, and can be 
                                    manually deleted by any user, at any time.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Link className="btn btn-outline-danger" to="/">Return Home</Link>
                                </div>                                
                            </Alert>
                        </>
                    )}
                    {this.state.deleted && (
                        <>
                            <Alert variant="success">
                                <Alert.Heading>Message Deleted</Alert.Heading>
                                <p>
                                    The specified e-mail has been removed and is no longer available in SMTP Bucket.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Link className="btn btn-outline-success" to="/">Return Home</Link>
                                </div>
                            </Alert>
                        </>
                    )}
                    {!this.state.loading && !this.state.deleted && this.state.email && (
                        <>
                            {this.state.backLink && this.state.backLink}
                            <Card className="mt-3 mb-3">
                                <Card.Header>Message Details</Card.Header>
                                <Card.Body>
                                    <Table striped bordered className="mb-0">
                                        <tbody>
                                            <tr>
                                                <th>Subject</th>
                                                <td>{this.state.email.subject || 'No Subject'}</td>
                                            </tr>
                                            <tr>
                                                <th>Sender</th>
                                                <td>{this.state.email.sender}</td>
                                            </tr>
                                            <tr>
                                                <th>Recipients</th>
                                                <td>
                                                    {this.state.email.recipients.map((e) => {
                                                        return <React.Fragment key={e}>{e}<br/ ></React.Fragment>
                                                    })}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Timestamp</th>
                                                <td>{dayjs.unix(this.state.email.timeCreated / 1000).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="text-center" style={{fontSize: 14}}>
                                                    Your donations help keep this service free. Thanks for all your support!
                                                    <div className="mt-2">
                                                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                                                            <input type="hidden" name="business" value="GRF9CCE3X9PQY" />
                                                            <input type="hidden" name="no_recurring" value="0" />
                                                            <input type="hidden" name="item_name" value="SMTP Bucket is used by hundreds of developers every day, and your donations help keep it free. Thanks for all your support!" />
                                                            <input type="hidden" name="currency_code" value="USD" />
                                                            <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                                            <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                                                        </form>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Alert variant="info" style={{"wordBreak": "break-word"}}>
                                <strong>REST API call for this e-mail: </strong>
                                GET <a href={this.state.restUri.toString()} target="_new">{this.state.restUri.toString()}</a>
                            </Alert>
                            <Card className="mt-3 mb-3">
                                <Card.Header>
                                    {this.state.email.html && (
                                        <>
                                            <Nav variant="tabs" defaultActiveKey="html" onSelect={(selectedKey) => this.setState({show: selectedKey})}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="html">HTML</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="raw">Raw</Nav.Link>
                                                </Nav.Item>
                                            </Nav>                                        
                                        </>
                                    )}
                                    {!this.state.email.html && 'Message Body'}
                                </Card.Header>
                                <Card.Body>
                                    {this.state.show === 'html' && (
                                        <>
                                            <HtmlView id={this.state.id}/>
                                        </>
                                    )}
                                    {this.state.show === 'raw' && (
                                        <>
                                            <pre>
                                                {this.state.email.body}
                                            </pre>
                                        </>
                                    )}                                    
                                </Card.Body>                                
                            </Card>
                            <Button variant="danger" className="mb-3" onClick={this.showConfirmDelete}>Delete</Button>
                            <Modal show={this.state.showConfirmDelete} animation={false} onHide={this.hideConfirmDelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Are you sure?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete this e-mail? This cannot be undone.</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.deleteEmail}>
                                        I'm sure. Delete the e-mail.
                                    </Button>
                                    <Button variant="primary" onClick={this.hideConfirmDelete}>
                                        Cancel
                                    </Button>                                    
                                </Modal.Footer>
                            </Modal>                            
                        </>
                    )}
                </Container>                
            </>
        )
    }
}

class HtmlView extends React.Component {

    componentDidMount() {
        this.timerId = setInterval(() => {
            const iframe = document.getElementById('iframe');
            if (iframe && window.location.hostname !== 'localhost') {
                const css = iframe.contentWindow.document.body.scrollHeight + 'px';;
                if (iframe.style.height !== css) {
                    iframe.style.height = css;
                }
            }
        }, 200);        
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render() {
        const host = window.location.hostname === 'localhost' ? process.env.REACT_APP_SMTPBUCKET_API : '';
        return <iframe
            id="iframe"
            className="border-0"
            style={{width: '100%'}}
            title="HTML view"
            src={`${host}/emails/${this.props.id}/html`}></iframe>;
    }
   
}

export default withRouter(Email);