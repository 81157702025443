import React from 'react';
import { isIOS, isTablet } from 'react-device-detect';

class IpadScrollFix extends React.Component {

    componentDidMount() {
        if (isIOS && isTablet) {
            window.scrollTo(0, 1);
            window.scrollTo(0, 0);
        }        
    }

    render() {
        return null;
    }
}

export default IpadScrollFix;

