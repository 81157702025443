import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

function API() {
    useEffect(() => {
        document.title = 'SMTP Bucket - REST API';
    });
    return <>
        <Container>
            <Row>
                <Col xl="3">
                    <div className="sticky-top mt-md-2">
                        <h2 className="d-xl-none mt-2">REST API</h2>
                        <p>
                            Our REST API supports the following operations:
                        </p>  
                        <ul>
                            <li><a href="#search-for-emails">Search for e-mails</a></li>
                            <li><a href="#get-email">Get e-mail</a></li>
                            <li><a href="#get-html">Get HTML</a></li>
                            <li><a href="#delete-email">Delete e-mail</a></li>

                        </ul>
                    </div>
                </Col>
                <Col xl="9" className="mt-md-2">
                    <a id="search-for-emails" style={{paddingTop: "16px"}}></a>
                    <h2>Search for e-mails</h2>
                    <p>
                        You can search by sender or recipient (or both). At least one of the two must be provided.
                    </p>
                    <h3>Request</h3>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>Resource</th>
                                <td>https://api.smtpbucket.com/emails</td>
                            </tr>
                            <tr>
                                <th>Method</th>
                                <td>GET</td>
                            </tr>
                            <tr>
                                <th>Query Parameters</th>
                                <td>
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <th>sender</th>
                                                <td>The sender's email address</td>
                                            </tr>
                                            <tr>
                                                <th>recipient</th>
                                                <td>The recipient's email address</td>
                                            </tr>
                                            <tr>
                                                <th>page</th>
                                                <td>Page number (0 indexed). Default: 0</td>
                                            </tr>
                                            <tr>
                                                <th>limit</th>
                                                <td>Maximum number of results per page. Default: 10, Maximum: 100</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Response</h3>
                    <p>
                        The "results" field contains a summarised list of matching e-mails, in the order they were received (newest first). For each e-mail we return the following data:
                    </p>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>id</th>
                                <td>A unique identifer for this e-mail</td>
                            </tr>
                            <tr>
                                <th>sender</th>
                                <td>The sender's email address</td>
                            </tr>
                            <tr>
                                <th>recipients</th>
                                <td>A list of e-mail addresses this e-mail would have been delivered to</td>
                            </tr>
                            <tr>
                                <th>subject</th>
                                <td>The e-mail's subject line (if provided)</td>
                            </tr>
                            <tr>
                                <th>timeCreated</th>
                                <td>The time this e-mail was received by SMTP Bucket, in milliseconds since 00:00 January 1, 1970 (UTC)</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        We also return the following metadata about the result set:
                    </p>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>totalResults</th>
                                <td>The total number of results that match the specified query parameters</td>
                            </tr>
                            <tr>
                                <th>totalPages</th>
                                <td>The number of pages of results available (at the specified number of results per page)</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Example</h3>
                    <pre>{`GET https://api.smtpbucket.com/emails?sender=sender%40example.com&recipient=recipient%40example.com&page=0&limit=2

{
    "results": [
        {
            "id": "2c9080c356db0d5f0156db35315a0000",
            "sender": "sender@example.com",
            "recipients": [
                "recipient@example.com"
            ],
            "subject": "This is the subject line!",
            "timeCreated": 1472556512000
        },
        {
            "id": "3c9080c356db0d5f0156db35315a0000",
            "sender": "sender@example.com",
            "recipients": [
                "recipient@example.com"
            ],
            "subject": "This is the subject line!",
            "timeCreated": 1472556511000
        }
    ],
    "totalResults": 20,
    "totalPages": 10
}`}</pre>

                    <a id="get-email" style={{paddingTop: "16px"}}></a>
                    <hr />
                    <h2>Get e-mail</h2>                    
                    <p>
                        Use the unique identifier returned by <a href="#search-for-emails">'Search for e-mails'</a> to retrieve full details about a specific e-mail (including the message body).
                    </p>
                    <h3>Request</h3>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>Resource</th>
                                <td>https://api.smtpbucket.com/emails/{'{id}'}</td>
                            </tr>
                            <tr>
                                <th>Method</th>
                                <td>GET</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Response</h3>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>id</th>
                                <td>A unique identifer for this e-mail</td>
                            </tr>
                            <tr>
                                <th>sender</th>
                                <td>The sender's email address</td>
                            </tr>
                            <tr>
                                <th>recipients</th>
                                <td>A list of e-mail addresses this e-mail would have been delivered to</td>
                            </tr>
                            <tr>
                                <th>subject</th>
                                <td>The e-mail's subject line (if provided)</td>
                            </tr>
                            <tr>
                                <th>body</th>
                                <td>The original message body. In most cases, this will be a MIME encoded e-mail message</td>
                            </tr>
                            <tr>
                                <th>html</th>
                                <td>Returns true if this is an HTML e-mail, false otherwise</td>
                            </tr>
                            <tr>
                                <th>timeCreated</th>
                                <td>The time this e-mail was received by SMTP Bucket, in milliseconds since 00:00 January 1, 1970 (UTC)</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Example</h3>
                    <pre>{`GET https://api.smtpbucket.com/emails/2c9080c356db0d5f0156db35315a0000

{
    "id" : "2c9080c356db0d5f0156db35315a0000",
    "sender" : "sender@example.com",
    "recipients" : [ "recipient@example.com" ],
    "subject" : "This is the subject line!",
    "body" : "From: sender@example.com\\r\\nTo: recipient@example.com\\r\\nMessage-ID: 51228289.0.1472556511530@My-PC.lan>\\r\\nSubject: This is the subject line!\\r\\nMIME-Version: 1.0\\r\\nContent-Type: text/plain; charset=us-ascii\\r\\nContent-Transfer-Encoding: 7bit\\r\\n\\r\\nThis is the message body!\\r\\n",
    "html" : false,
    "timeCreated" : 1472556512000
}`}</pre>
                    <a id="get-html" style={{paddingTop: "16px"}}></a>
                    <hr />
                    <h2>Get HTML</h2>
                    <p>
                        Use the unique identifier returned by <a href="#search-for-emails">'Search for e-mails'</a> to retrieve an HTML view of the specified e-mail (if available)
                    </p>
                    <h3>Request</h3>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>Resource</th>
                                <td>https://api.smtpbucket.com/emails/{'{id}'}/html</td>
                            </tr>
                            <tr>
                                <th>Method</th>
                                <td>GET</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Example</h3>
                    <pre>{`GET https://api.smtpbucket.com/emails/2c9080c356db0d5f0156db35315a0000/html

<h1>This is an HTML e-mail</h1>`}</pre>
                    <a id="delete-email" style={{paddingTop: "16px"}}></a>
                    <hr />
                    <h2>Delete e-mail</h2>
                    <p>
                        Use the unique identifier returned by <a href="#search-for-emails">'Search for e-mails'</a> to permanently delete a specific e-mail
                    </p>
                    <h3>Request</h3>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th>Resource</th>
                                <td>https://api.smtpbucket.com/emails/{'{id}'}</td>
                            </tr>
                            <tr>
                                <th>Method</th>
                                <td>DELETE</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h3>Example</h3>
                    <pre>DELETE https://api.smtpbucket.com/emails/2c9080c356db0d5f0156db35315a0000</pre>
                </Col>
            </Row>
        </Container>
    </>;
}

export default API;