import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import queryString from 'query-string';

import Home from './Home'
import Emails from './Emails'
import Email from './Email'
import API from './API'
import SmtpTutorial from './SmtpTutorial'

import { Alert } from 'react-bootstrap';

import './App.css';

function useQuery() {
  return queryString.parse(useLocation().search);
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Routes() {
  let query = useQuery();
  return (
    <Switch>
      <Route exact path="/emails/:id">
        <Email />
      </Route>
      <Route exact path="/emails">
        <Emails sender={query.sender} recipient={query.recipient} />
      </Route>
      <Route exact path="/api">
        <API />
      </Route>
      <Route exact path="/guides/a-brief-tutorial-on-the-smtp-protocol">
        <SmtpTutorial />
      </Route>      
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );  
}

function NoMatch() {
  return (
    <Container>
      <Alert variant="danger" className="mt-3">
        <Alert.Heading>404 - Not found</Alert.Heading>
        <p>
          The page you requested doesn't seem to exist. Please update your links or bookmarks.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link className="btn btn-outline-danger" to="/">Return Home</Link>
        </div>
      </Alert>
    </Container>
  );
}

function App() { 
  return (
    <Router>
      <ScrollToTop /> 
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <LinkContainer to="/"><Navbar.Brand>SMTP Bucket</Navbar.Brand></LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/emails" active={false}><Nav.Link>Search e-mails</Nav.Link></LinkContainer>
              <LinkContainer to="/api" active={false}><Nav.Link href="/api">API</Nav.Link></LinkContainer>
              <NavDropdown title="Guides" id="basic-nav-dropdown">
                <LinkContainer to="/guides/a-brief-tutorial-on-the-smtp-protocol"><NavDropdown.Item active={false}>A brief tutorial on the SMTP protocol</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>          
        </Container>
      </Navbar>
      <Routes />
    </Router>
  );
}

export default App;
