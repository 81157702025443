import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { withRouter } from 'react-router-dom';

class SearchForm extends React.Component {

    constructor(props) {
        super(props);
        const sender = props.sender || '';
        const recipient = props.recipient || '';
        const readyToSubmit = sender !== '' || recipient !== '';
        this.state = {
            sender,
            recipient,
            initialSender: sender,
            initialRecipient: recipient,
            readyToSubmit,
            changed: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {        
        const sender = this.props.sender || '';
        const recipient = this.props.recipient || '';
        const prevSender = prevProps.sender || '';
        const prevRecipient = prevProps.recipient || '';
        if (sender !== prevSender || recipient !== prevRecipient) {
            const readyToSubmit = sender !== '' || recipient !== '';
            this.setState({
                sender,
                recipient,
                initialSender: sender,
                initialRecipient: recipient,
                changed: false,
                readyToSubmit,
            });
        }
    }
    
    handleChange(event) {
        event.preventDefault();
        const target = event.target;        
        const name = target.name;
        let value = target.value;
        if (value) {
            value = value.trim();
        }
    
        this.setState({
            [name]: value
        }, () => {
            const readyToSubmit = this.state.sender !== '' || this.state.recipient !== '';
            const changed = this.state.sender !== this.state.initialSender || this.state.recipient !== this.state.initialRecipient;
            this.setState({
                readyToSubmit,
                changed
            });
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const sender = this.state.sender;
        const recipient = this.state.recipient;            
        let query = '';
        if (sender) {
            query += `sender=${sender}`;
            if (recipient) {
                query += '&';
            }
        }
        if (recipient) {
            query += `recipient=${recipient}`;
        }
        if (this.state.changed) {
            this.props.history.push(`/emails?${query}`);
        } else if (this.props.onReload) {
            this.props.onReload();
        }
    }

    render() {
        return (
            <Form inline onSubmit={this.handleSubmit}>
                <Form.Control placeholder="Sender" htmlSize="27" name="sender" value={this.state.sender} onChange={this.handleChange} className="mr-2 mb-2 mb-lg-0" />
                <Form.Control placeholder="Recipient" htmlSize="27" name="recipient" value={this.state.recipient} onChange={this.handleChange} className="mr-2 mb-2 mb-lg-0" />
                <Button variant="primary" type="submit" disabled={!this.state.readyToSubmit} className="mr-2 mb-2 mb-lg-0">Search</Button><br/>
            </Form>
        );
    }
}

export default withRouter(SearchForm);