import React from 'react';

import { Link } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import SearchForm from './SearchForm';
import IpadScrollFix from './IpadScrollFix';

import dayjs from 'dayjs'

class Emails extends React.Component {

    constructor(props) {
        super(props);
        const sender = props.sender || '';
        const recipient = props.recipient || '';
        const readyToSearch = sender !== '' || recipient !== '';
        this.state = {
            sender: sender,
            recipient: recipient,
            readyToSearch: readyToSearch,
            searching: false,
            success: false,
            error: false,
            result: null,
            restUri: null
        }
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const sender = this.props.sender || '';
        const recipient = this.props.recipient || '';
        const prevSender = prevProps.sender || '';
        const prevRecipient = prevProps.recipient || '';
        if (sender !== prevSender || recipient !== prevRecipient) {
            const readyToSearch = sender !== '' || recipient !== '';
            this.setState({
                sender: sender,
                recipient: recipient,
                readyToSearch: readyToSearch,
                searching: false,
                success: false,
                error: false,
                result: null,
                restUri: null                
            }, () => this.fetchData());
        }
    }

    fetchData() {
        if (!this.state.readyToSearch) {
            return;
        }
        const host = process.env.REACT_APP_SMTPBUCKET_API;
        const url = new URL(host + "/emails");
        if (this.state.sender) {
            url.searchParams.append("sender", this.state.sender);
        }
        if (this.state.recipient) {
            url.searchParams.append("recipient", this.state.recipient);
        }
        url.searchParams.append("limit", 100);
        this.setState({
            searching: true,
            success: false,
            error: false,
            result: null,
            restUri: url
        });
        fetch(url)
          .then(rest => rest.json())
          .then(
            (result) => {
              this.setState({
                searching: false,
                success: true,
                result: result
              });
            },
            (error) => {
              this.setState({
                searching: false,
                error: true
              });
            }
          )
    }

    render() {
        return (
            <Container>
                <Card className="mt-3 mb-3">
                    <Card.Header>Search for e-mails</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Search for captured e-mails here. You can search by sender, recipient, or both.
                        </Card.Text>
                        <SearchForm sender={this.props.sender} recipient={this.props.recipient} onReload={ this.fetchData }/>
                    </Card.Body>
                </Card>
                {this.state.error && (
                    <Alert variant="danger">
                        An unexpected error occured. Please try your search again.
                    </Alert>
                )}
                {this.state.searching && <div className="text-center"><Spinner animation="border" variant="primary" /></div> }
                {this.state.success && (
                    <>                        
                        <Alert variant="info" style={{"wordBreak": "break-word"}}>
                            <strong>REST API call for this search: </strong>
                            GET <a href={this.state.restUri.toString()} target="_new">{this.state.restUri.toString()}</a>
                        </Alert>
                        <Alert variant="success">
                            {this.state.result.results.length < this.state.result.totalResults && (
                                <>
                                    Showing {this.state.result.results.length} out of a total of {this.state.result.totalResults} matching e-mails
                                </>
                            )}
                            {this.state.result.results.length === this.state.result.totalResults && (
                                <>
                                    Found {this.state.result.results.length} matching e-mails
                                </>
                            )}                            
                        </Alert>
                        {this.state.result.results.length > 0 &&
                            <>
                                <IpadScrollFix />
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th className="d-none d-lg-table-cell">Sender</th>
                                            <th>Subject</th>
                                            <th className="d-none d-lg-table-cell">Recipients</th>
                                            <th style={{width: '21%'}}>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="4" className="text-center" style={{fontSize: 14}}>
                                                Your donations help keep this service free. Thanks for all your support!
                                                <div className="mt-2">
                                                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                                                        <input type="hidden" name="business" value="GRF9CCE3X9PQY" />
                                                        <input type="hidden" name="no_recurring" value="0" />
                                                        <input type="hidden" name="item_name" value="SMTP Bucket is used by hundreds of developers every day, and your donations help keep it free. Thanks for all your support!" />
                                                        <input type="hidden" name="currency_code" value="USD" />
                                                        <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                                        <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                                                    </form>
                                                </div>
                                            </td>
                                        </tr>
                                        {this.state.result.results.map((r) => (
                                            <tr key={r.id}>
                                                <td className="d-none d-lg-table-cell">{r.sender}</td>
                                                <td><Link to={
                                                    {
                                                        pathname: `/emails/${r.id}`,
                                                        state: {
                                                            fromSearch: true,
                                                            sender: this.state.sender,
                                                            recipient: this.state.recipient
                                                        }
                                                    }}>{r.subject ? r.subject : 'No Subject'}</Link>
                                                </td>
                                                <td className="d-none d-lg-table-cell">
                                                    {r.recipients.map((e, i) => (
                                                        <React.Fragment key={`${r.id}-${i}`}><span>{ e }</span><br /></React.Fragment>
                                                    ))}
                                                </td>
                                                <td>{dayjs.unix(r.timeCreated / 1000).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        }
                    </>
                )}
            </Container>            
        );
    };    
}

export default Emails;