import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container'

function SmtpTutorial() {
    useEffect(() => {
        document.title = 'SMTP Bucket - a brief tutorial on the SMTP protocol';
    });
    return <>
        <Container>
            <h2 className="mt-2">A brief tutorial on the SMTP protocol</h2>
            <p>The Simple Mail Transfer Protocol (SMTP) is, as its name suggests, not particularly complicated. You can easily work your way through a typical SMTP session by hand using the command line telnet client. Telnet is installed by default on most Linux distributions and macOS, but is disabled in recent versions of Windows. If you&#8217;re a Windows user, you&#8217;ll need to follow the steps documented here to resurrect the telnet client before continuing: <a href="https://www.rootusers.com/how-to-enable-the-telnet-client-in-windows-10/">https://www.rootusers.com/how-to-enable-the-telnet-client-in-windows-10/</a></p>
            <p>In this tutorial, we are going to use SMTP Bucket as our mail server, which is available at mail.smtpbucket.com and listens on port 8025. SMTP Bucket is a fake SMTP Server that simply stores all the e-mails it receives in a database and makes them available through its <a href="https://www.smtpbucket.com/emails">website</a> and <a href="https://www.smtpbucket.com/api">REST API</a>. Let&#8217;s see what happens when we establish a connection using telnet:</p>
            <pre>
                &gt; telnet mail.smtpbucket.com 8025<br />
                Trying 212.71.250.90...<br />
                Connected to smtpbucket.com.<br />
                Escape character is '^]'.<br />
                <span style={{ color: "#ff4422" }}>220 mail.smtpbucket.com</span>
            </pre>
            <p>The server responds with status code 220 and its own identity (mail.smtpbucket.com in this case). Throughout this tutorial, the SMTP server&#8217;s responses are shown in <span style={{ color : '#ff4422' }}>red</span>. SMTP commands issued by the client (i.e. things you type into the telnet window) will be shown in <span style={{ color : '#0088ff' }}>blue</span>. Next, the client (i.e. you) begins the session by issuing the &#8216;HELO&#8217; command, to which the server replies with status code 250, which indicates the last command was successfully completed.</p>
            <pre>
                <span style={{ color : '#0088ff' }}>HELO</span><br />
                <span style={{ color : '#ff4422' }}>250 Hello</span>
            </pre>
            <p>Well, actually, that&#8217;s not strictly true. Most mail servers support various extensions to the core SMTP protocol (most commonly for things like authentication). Clients typically request a list of supported extensions by issuing the non-standard &#8216;EHLO&#8217; command instead of HELO. SMTP Bucket doesn&#8217;t currently support anything not offered by standard SMTP, so it doesn&#8217;t understand EHLO and will respond with status 502 (Not supported). That will trigger the client to fall back to standard SMTP and issue the HELO command (unless it has been configured to abandon the session if a required extension is not supported &#8211; e.g. many e-mail clients have a &#8216;Require Authentication&#8217; option).</p>
            <p>Anyway, once this simple handshake is complete, we can begin sending e-mail. That&#8217;s initiated with the &#8216;MAIL FROM&#8217; command, which also includes the e-mail address of the sender:</p>
            <pre>
                <span style={{ color : '#0088ff' }}>MAIL FROM:&lt;smtptutorial_sender@example.com&gt;</span><br />
                <span style={{ color : '#ff4422' }}>250 Sender OK</span>
            </pre>
            <p>Then we specify the recipient using &#8216;RCPT TO&#8217;:</p>
            <pre>
                <span style={{ color : '#0088ff' }}>RCPT TO:&lt;smtptutorial_recipient@example.com&gt;</span><br />
                <span style={{ color : '#ff4422' }}>250 Recipient OK</span>
            </pre>
            <p>Now it&#8217;s time to send the message body. We begin this process with the &#8216;DATA&#8217; command. Any subsequent lines are interpreted as e-mail data until we send a single &#8216;.&#8217; on a line by itself:</p>
            <pre>
                <span style={{ color : '#0088ff' }}>DATA</span><br />
                <span style={{ color : '#ff4422' }}>354 Start mail input; end with &lt;CRLF&gt;.&lt;CRLF&gt;</span><br />
                <span style={{ color : '#0088ff' }}>Subject:Subject line goes here!<br />
                <br />
                The message body goes here!<br />
                .<br />
                </span>
            <span style={{ color : '#ff4422' }}>250 Message accepted</span></pre>
            <p>Hopefully the purpose of the &#8216;Subject&#8217; header is self explanatory, but note the need for a double line break separating it from the actual e-mail text. The server then responds with a final 250 status code to indicate the message has been accepted. If this were a real SMTP Server and not a fake one, this e-mail would now be on its way to smtptutorial_recipient@example.com. We could continue sending additional e-mails in this session, but our work here is done, so we issue the &#8216;QUIT&#8217; command to close the connection:</p>
            <pre>
                <span style={{ color : '#0088ff' }}>QUIT</span><br />
                Connection closed by foreign host.
            </pre>
            <p>As we&#8217;re using SMTP Bucket, we can now use its <a href="https://www.smtpbucket.com/api">REST API</a> to find the e-mail we just sent:</p>
            <pre>{`> curl -G https://api.smtpbucket.com/emails --data-urlencode "sender=smtptutorial_sender@example.com"
{
    "results" : [ {
        "id" : "ff8081815974a2a30159c824b0b00105",
        "sender" : "smtptutorial_sender@example.com",
        "recipients" : [ "smtptutorial_recipient@example.com" ],
        "subject" : "Subject line goes here!",
        "timeCreated" : 1485121564000
    } ]
}`}</pre>
            <p>We can see that our e-mail has been saved with ID &#8216;ff8081815974a2a30159c824b0b00105&#8217;, and the sender and recipient are as we provided in the original request. Now we can retrieve the full e-mail with another GET request using its allocated ID:</p>
            <pre>{`> curl https://api.smtpbucket.com/emails/ff8081815974a2a30159c824b0b00105
{
    "id" : "ff8081815974a2a30159c824b0b00105",
    "sender" : "smtptutorial_sender@example.com",
    "recipients" : [ "smtptutorial_recipient@example.com" ],
    "subject" : "Subject line goes here",
    "body" : "Subject:Subject line goes here\\n\\nThis is the message body\\n",
    "timeCreated" :1485121564000
}`}</pre>
            <p>We hope this tutorial has been useful. If you&#8217;d like to learn more about the SMTP protocol, it is formally defined in RFC 2821, which is available here: <a href="https://www.ietf.org/rfc/rfc2821.txt">https://www.ietf.org/rfc/rfc2821.txt</a></p>            
        </Container>
    </>;
}

export default SmtpTutorial;