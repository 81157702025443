import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'
import { Link } from 'react-router-dom';

import SearchForm from './SearchForm'

function Home() {
    useEffect(() => {
        document.title = 'SMTP Bucket - the fake SMTP server';
    });
    return <>
        <Jumbotron fluid>
            <Container>
                <h1>Welcome to SMTP Bucket</h1>
                <p>
                    SMTP Bucket is a fake SMTP server that captures all the e-mails it receives and makes them available
                    through our website and REST API. Point your app at <b>mail.smtpbucket.com</b> (port <b>8025</b>)
                    and find your e-mails by sender or recipient here:
                </p>
                <SearchForm />
            </Container>
        </Jumbotron>
        <Container>
            <h4>What is SMTP Bucket?</h4>
            <p>
                SMTP Bucket implements the Simple Mail Transfer Protocol (SMTP), but it doesn't try to deliver the e-mails it receives.
                It's useful in development and test environments, where you don't want to risk spamming real users with test e-mails.
                Please send any feedback to <a href="mailto:support@smtpbucket.com">support@smtpbucket.com</a>.
            </p>
            <h4>How long do you keep my e-mails?</h4>
            <p>
                30 days. But you can delete them sooner using the REST API or web interface.
            </p>
            <h4>How much does it cost?</h4>
            <p>
                It's free, and I'd very much like to keep it that way. But it's costing more and more to host as it becomes more widely used, so any donations are much appreciated!
            </p>
            <div className="pt-2 pb-2 text-center">
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="business" value="GRF9CCE3X9PQY" />
                    <input type="hidden" name="no_recurring" value="0" />
                    <input type="hidden" name="item_name" value="SMTP Bucket is used by hundreds of developers every day, and your donations help keep it free. Thanks for all your support!" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </div>            
            <h4>How do I use it?</h4>
            <p>
                Just like any other SMTP Server. For example, in Java:
            </p>
            <pre>{`import javax.mail.Message;
import javax.mail.Session;
import javax.mail.Transport;
import javax.mail.internet.InternetAddress;
import javax.mail.internet.MimeMessage;

import java.util.Properties;

public class MailSender {
    public static void main(String... args) throws Exception {
        Properties properties = System.getProperties();
        properties.setProperty("mail.smtp.host", "mail.smtpbucket.com");
        properties.setProperty("mail.smtp.port", "8025");

        Session session = Session.getDefaultInstance(properties);

        MimeMessage message = new MimeMessage(session);
        message.setFrom(new InternetAddress("sender@example.com"));
        message.addRecipient(Message.RecipientType.TO, new InternetAddress("recipient@example.com"));
        message.setSubject("This is the subject line!");
        message.setText("This is the message body!");
        Transport.send(message);
    }
}`}</pre>
            <h4>Do you have an API?</h4>
            <p>
                Of course. It's fully documented <Link to="/api">here</Link>, but here are the basics. To search e-mails:
            </p>
            <pre>{`curl -G https://api.smtpbucket.com/emails --data-urlencode "sender=sender@example.com"
{
    "results": [
        {
            "id": "2c9080c356db0d5f0156db35315a0000",
            "sender": "sender@example.com",
            "recipients": [
                "recipient@example.com"
            ],
            "subject": "This is the subject line!",
            "timeCreated": 1472556512000
        }
    ],
    "totalResults": 1,
    "totalPages": 1
}`}</pre>
            <p>
                To retrieve a single e-mail, including its body:
            </p>
            <pre>{`curl https://api.smtpbucket.com/emails/2c9080c356db0d5f0156db35315a0000
{
    "id" : "2c9080c356db0d5f0156db35315a0000",
    "sender" : "sender@example.com",
    "recipients" : [ "recipient@example.com" ],
    "subject" : "This is the subject line!",
    "body" : "From: sender@example.com\\r\\nTo: recipient@example.com\\r\\nMessage-ID: <51228289.0.1472556511530@My-PC.lan>\\r\\nSubject: This is the subject line!\\r\\nMIME-Version: 1.0\\r\\nContent-Type: text/plain; charset=us-ascii\\r\\nContent-Transfer-Encoding: 7bit\\r\\n\\r\\nThis is the message body!\\r\\n",
    "html" : false,
    "timeCreated" : 1472556512000
}`}</pre>
        </Container>
    </>;
}

export default Home;